/** ***************************************************************************
 * Template / Homepage PB
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

body {
  --lm-c-homepage--MaxContentWidth: 90rem; // 1440px

  a[href],
  .lm-u-button {
    @media all and not (pointer:coarse) {
      cursor: none;
    }
  }

  .lm-c-button-icon {
    --lm-c-button-icon--PaddingBlock: .67em;
    --lm-c-button-icon--PaddingInline: 1.23em;
    font: var(--lm-global--Font-label--md-medium);

    > * {
      position: relative;
      z-index: 1;
    }

  }

  .lm-u-button.lm-m-primary.lm-c-button-play.lm-c-button-play--pb {
    font: var(--lm-global--Font-label--md-medium);

    > .inner {
      padding-block: .67em;
      padding-inline: 1.23em;
    }
  }

  .lm-u-button,
  .lm-u-button.lm-m-primary.lm-c-button-play.lm-c-button-play--pb {
    --data-before-top: 0;
    --data-before-left: 0;
    position: relative;
    overflow: hidden;
    color: var(--lm-global-common--palette-kiss);

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: var(--data-before-top);
      left: var(--data-before-left);
      background-color: currentColor;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform .5s cubic-bezier(.23,1,.32,1);
    }

    &:hover {
      &:before {
        color: var(--lm-global-common--palette-kiss);
        transform: translate(-50%, -50%) scale(2.5);
        display: block;
        padding-top: 100%;
      }
    }
  }

  .lm-u-button.lm-m-primary.lm-c-button-play.lm-c-button-play--pb {
    &:before {
      transition-duration: .8s;
    }
  }

  .separator {
    display: none;
    max-width: var(--lm-c-homepage--MaxContentWidth);
    margin-inline: auto;

    hr {
      margin-inline: var(--lm-global--page--paddingsize);
      color: var(--lm-global--palette-dark-grey--30);
    }

    @media all and (min-width: 64rem) {
      display: block;
    }
  }

  .lm-c-cursor {
    display: none;
    --fluid-711-10: clamp(0.4444rem, -0.0002rem + 0.6947vi, 0.625rem);
    --lm-c-cursor__color: var(--lm-global-common--palette-kiss);
    --lm-c-cursor__dimension: var(--fluid-711-10);

    .lm-c-button-icon {
      color: inherit;
    }

    @media all and not (pointer:coarse) {
      display: inherit;
    }
  }

  @media all and not (pointer:coarse) {
    cursor: none;
  }
}

.lm-c-header {
  @include utopia.generateClamps((
    "minWidth": 768,
    "maxWidth": 1440,
    "pairs": (
      (40, 57),
    ),
    "prefix": "fluid-",
  ));
  display: grid;
  grid-template-columns: var(--lm-c-page--Grid-Template);
  padding-inline: var(--lm-global--page--paddingsize);
  padding-block: .938rem;

  @media all and (min-width: 48rem) {
    padding-block: var(--fluid-40-57);
  }
}

.lm-c-headline {
  @include utopia.generateClamps((
    "minWidth": 375,
    "maxWidth": 1024,
    "pairs": (
      (61, 0),
    ),
    "prefix": "fluid-",
  ));
  @include utopia.generateClamps((
    "minWidth": 1024,
    "maxWidth": 1440,
    "pairs": (
      (1, 10),
      (22, 31),
    ),
    "prefix": "fluid-",
  ));
  max-width: var(--lm-c-homepage--MaxContentWidth);
  padding-inline: var(--lm-global--page--paddingsize);
  margin-inline: auto;
  padding-block-start: var(--fluid-61-0);
  margin-block-end: var(--fluid-22-31);

  @media all and (min-width: 64rem) {
    padding-block-start: var(--fluid-1-10);
  }
}

main {

  > .container {
    display: grid;
    grid-template-columns: var(--lm-c-page--Grid-Template);
    grid-gap: 0 var(--lm-c-page--Grid-Gap);
    padding-inline: var(--lm-global--page--paddingsize);

    max-width: var(--lm-c-homepage--MaxContentWidth);
    margin-inline: auto;
  }

  section.lm-c-tutorial {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (10, 15),
      ),
      "prefix": "fluid-",
    ));
    margin-block-start: 0;

    @media all and (min-width: 64rem) {
      margin-block-start: var(--fluid-10-15);
    }
  }

  .lm-c-partners_slider {
    @include utopia.generateClamps((
      "minWidth": 375,
      "maxWidth": 1440,
      "pairs": (
        (0, 70),
        (40, 150),
      ),
      "prefix": "fluid-",
    ));
    padding-block-start: var(--fluid-40-150);
    padding-block-end: var(--fluid-0-70);
  }

  .lm-c-quotes_slider {
    @include utopia.generateClamps((
      "minWidth": 375,
      "maxWidth": 1440,
      "pairs": (
        (40, 150),
      ),
      "prefix": "fluid-",
    ));
    padding-block-start: var(--fluid-40-150);
  }

  .lm-c-generic_section {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (74, 104),
        (124, 175),
      ),
      "prefix": "fluid-",
    ));
    margin-block: 4.5rem 3.125rem;

    &.lm-m-button_only {
      .lm-u-button:before {
        background-color: var(--lm-global--palette-dark-blue);
      }
    }

    @media all and (min-width: 64rem) {
      margin-block: var(--fluid-74-104) var(--fluid-124-175);
    }
  }

  .lm-c-quotes_slider {
    .splide__arrow.lm-u-button {
      position: absolute;
    }
  }

  @media all and (min-width: 64rem) {
    background-image: var(--lm-global--palette-gradient--background);
  }
}

.lm-c-pre_footer {
  .lm-u-button:before {
    background-color: var(--lm-global--palette-aqua);
  }
}

.mobile-background {
  background-image: var(--lm-global--palette-gradient--background);

  @media all and (min-width: 64rem) {
    background-image: none;
  }
}
